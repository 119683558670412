<template>
  <div class v-loading="loadingData">
    <el-row :gutter="20">
      <!-- <el-col :md="4" :xl="4" v-can-or="['schedules']">
        <router-link class="click_link" :to="{name: 'schedules'}">
          <span @click="changeCurrentTab('pills-schedules')">
            <div class="itme_blocks_pages">
              <div class="itme_blocks_pages_top">
                <i class="iconstyle iconitme_blocks_pages1"></i>
                <div class="info_topright">
                  <i class="el-icon-view"></i>
                </div>
              </div>
              <div class="itme_blocks_pages_botton">
                <div class="itme_blocks_pages_botton_summa">{{schedulePatients ? schedulePatients : 0}} {{ $t('message.pieces') }}</div>
                <div class="itme_blocks_pages_botton_title">{{$t('message.schedule')}}</div>
              </div>
            </div>
          </span>
        </router-link>
      </el-col> -->
      <!-- end el-col -->

      <el-col :md="4" :xl="4" v-can-or="['patients']">
        <router-link class="click_link" :to="{ name: 'patients' }">
          <span @click="changeCurrentTab('pills-sevrvices')">
            <div class="itme_blocks_pages">
              <div class="itme_blocks_pages_top">
                <i class="iconstyle iconitme_blocks_pages2"></i>
                <div class="info_topright">
                  <i class="el-icon-view"></i>
                </div>
              </div>
              <div class="itme_blocks_pages_botton">
                <div class="itme_blocks_pages_botton_summa">
                  {{ schedulePatients ? schedulePatients : 0 }}
                  {{ $t("message.pieces") }}
                </div>
                <div class="itme_blocks_pages_botton_title">
                  {{ $t("message.patients") }}
                </div>
              </div>
            </div>
          </span>
        </router-link>
      </el-col>
      <!-- end el-col -->

      <!-- <el-col :md="4" :xl="4" v-can="'hospitals.index'" v-if="env_hospital ==1">
        <router-link class="click_link" :to="{name: 'hospital'}">
          <span @click="changeCurrentTab('pills-hospital')">
            <div class="itme_blocks_pages">
              <div class="itme_blocks_pages_top">
                <i class="iconstyle iconitme_blocks_pages3"></i>
                <div class="info_topright">
                  <i class="el-icon-view"></i>
                </div>
              </div>
              <div class="itme_blocks_pages_botton">
                <div class="itme_blocks_pages_botton_summa">{{stasionaryPatients ? stasionaryPatients : 0}} {{ $t('message.pieces') }}</div>
                <div class="itme_blocks_pages_botton_title">{{$t('message.hospital')}}</div>
              </div>
            </div>
          </span>
        </router-link>
      </el-col> -->
      <!-- end el-col -->

      <!-- <el-col :md="4" :xl="4" v-can="'nurseOffices.index'" v-if="env_medical ==1">
        <router-link class="click_link" :to="{name: 'nurseOffice'}">
          <span @click="changeCurrentTab('pills-hospital')">
            <div class="itme_blocks_pages">
            <div class="itme_blocks_pages_top">
              <i class="iconstyle iconitme_blocks_pages4"></i>
              <div class="info_topright">
                <i class="el-icon-view"></i>
              </div>
            </div>
            <div class="itme_blocks_pages_botton">
              <div class="itme_blocks_pages_botton_summa">14 {{ $t('message.pieces') }}</div>
              <div class="itme_blocks_pages_botton_title">{{$t('message.nurse_office')}}</div>
            </div>
          </div>
          </span>
        </router-link>
      </el-col> -->
      <!-- end el-col -->

      <el-col :md="4" :xl="4" v-can-or="['payments']">
        <router-link class="click_link" :to="{ name: 'payments' }">
          <span @click="changeCurrentTab('pills-cashiers')">
            <div class="itme_blocks_pages">
              <div class="itme_blocks_pages_top">
                <i class="iconstyle iconitme_blocks_pages5"></i>
                <div class="info_topright">
                  <i class="el-icon-view"></i>
                </div>
              </div>
              <div class="itme_blocks_pages_botton">
                <div class="itme_blocks_pages_botton_summa">
                  {{ paymentsSum ? paymentsSum : 0 | formatMoney }}
                  {{ $t("message.c_sum") }}
                </div>
                <div class="itme_blocks_pages_botton_title">
                  {{ $t("message.payments") }}
                </div>
              </div>
            </div>
          </span>
        </router-link>
      </el-col>
      <!-- end el-col -->

      <!-- <el-col :md="4" :xl="4" v-can-or="['settings']">
        <router-link class="click_link" :to="{name: 'contracts'}">
          <span @click="changeCurrentTab('pills-settings')">
            <div class="itme_blocks_pages">
              <div class="itme_blocks_pages_top">
                <i class="iconstyle iconitme_blocks_pages55"></i>
                <div class="info_topright">
                  <i class="el-icon-view"></i>
                </div>
              </div>
              <div class="itme_blocks_pages_botton">
                <div class="itme_blocks_pages_botton_summa">{{contractsCount ? contractsCount : 0}} {{ $t('message.pieces') }}</div>
                <div class="itme_blocks_pages_botton_title">{{$t('message.contracts')}}</div>
              </div>
            </div>
          </span>
        </router-link>
      </el-col> -->
      <!-- end el-col -->

      <!-- <el-col :md="4" :xl="4" v-can-or="['warehouses']">
        <router-link class="click_link" :to="{name: 'buyReadyProducts'}">
          <span @click="changeCurrentTab('pills-Warehouses')">
            <div class="itme_blocks_pages">
              <div class="itme_blocks_pages_top">
                <i class="iconstyle iconitme_blocks_pages6"></i>
                <div class="info_topright">
                  <i class="el-icon-view"></i>
                </div>
              </div>
              <div class="itme_blocks_pages_botton">
                <div class="itme_blocks_pages_botton_summa">{{expences ? expences : 0}} {{ $t('message.pieces') }}</div>
                <div class="itme_blocks_pages_botton_title">{{$t('message.purchasing_products')}}</div>
              </div>
            </div>
          </span>
        </router-link>
      </el-col> -->
      <!-- end el-col -->

      <el-col :md="4" :xl="4">
        <router-link class="click_link" :to="{ name: 'categoryFormTemplates' }">
          <span @click="changeCurrentTab('pills-form-templates')">
            <div class="itme_blocks_pages">
              <div class="itme_blocks_pages_top">
                <i class="iconstyle iconitme_blocks_pages8"></i>
                <div class="info_topright">
                  <i class="el-icon-view"></i>
                </div>
              </div>
              <div class="itme_blocks_pages_botton">
                <div class="itme_blocks_pages_botton_summa">
                  {{ formTemplates ? formTemplates : 0 }}
                  {{ $t("message.pieces") }}
                </div>
                <div class="itme_blocks_pages_botton_title">
                  {{ $t("message.forms_mkb") }}
                </div>
              </div>
            </div>
          </span>
        </router-link>
      </el-col>
      <!-- end el-col -->

      <!-- <el-col :md="4" :xl="4" v-can="'schedulesStasionary.index'" v-if="hospitalSchedule == 1">
        <router-link class="click_link" :to="{name: 'schedulesCabinet'}">
          <span @click="changeCurrentTab('pills-hospital')">
            <div class="itme_blocks_pages">
              <div class="itme_blocks_pages_top">
                <i class="iconstyle iconitme_blocks_pages11"></i>
                <div class="info_topright">
                  <i class="el-icon-view"></i>
                </div>
              </div>
              <div class="itme_blocks_pages_botton">
                <div class="itme_blocks_pages_botton_summa">{{patientScheduleCount ? patientScheduleCount : 0}} {{ $t('message.pieces') }}</div>
                <div class="itme_blocks_pages_botton_title">{{$t('message.schedule_cabinet')}}</div>
              </div>
            </div>
          </span>
        </router-link>
      </el-col> -->
      <!-- end el-col -->

      <el-col
        :md="4"
        :xl="4"
        v-can-or="[
          'doctor_cabinet.patients',
          'doctor_cabinet.index',
          'doctor_cabinet.AllPatients',
        ]"
      >
        <router-link class="click_link" :to="{ name: 'doctorCabinet' }">
          <span @click="changeCurrentTab('pills-doctorCabinet')">
            <div class="itme_blocks_pages">
              <div class="itme_blocks_pages_top">
                <i class="iconstyle iconitme_blocks_pages9"></i>
                <div class="info_topright">
                  <i class="el-icon-view"></i>
                </div>
              </div>
              <div class="itme_blocks_pages_botton">
                <!-- <div class="itme_blocks_pages_botton_summa">5 {{ $t('message.pieces') }}</div> -->
                <div class="itme_blocks_pages_botton_title">
                  {{ $t("message.my_office") }}
                </div>
              </div>
            </div>
          </span>
        </router-link>
      </el-col>
      <!-- end el-col -->

      <el-col
        :md="4"
        :xl="4"
        v-can-or="[
          'patients.index',
          'orders.index',
          'orderServiceTerm.index',
          'printCenter.printCenter',
        ]"
      >
        <router-link class="click_link" :to="{ name: 'orders' }">
          <span @click="changeCurrentTab('pills-sevrvices')">
            <div class="itme_blocks_pages">
              <div class="itme_blocks_pages_top">
                <i class="iconstyle iconitme_blocks_pages8"></i>
                <div class="info_topright">
                  <i class="el-icon-view"></i>
                </div>
              </div>
              <div class="itme_blocks_pages_botton">
                <div class="itme_blocks_pages_botton_summa">
                  {{ number_of_orders_today ? number_of_orders_today : 0 }}
                  {{ $t("message.pieces") }}
                </div>
                <div class="itme_blocks_pages_botton_title">
                  {{ $t("message.number_of_orders_today") }}
                </div>
              </div>
            </div>
          </span>
        </router-link>
      </el-col>
      <!-- end el-col -->

      <el-col
        :md="4"
        :xl="4"
        v-can-or="[
          'patients.index',
          'orders.index',
          'orderServiceTerm.index',
          'printCenter.printCenter',
        ]"
      >
        <router-link class="click_link" :to="{ name: 'orders' }">
          <span @click="changeCurrentTab('pills-sevrvices')">
            <div class="itme_blocks_pages">
              <div class="itme_blocks_pages_top">
                <i class="iconstyle iconitme_blocks_pages55"></i>
                <div class="info_topright">
                  <i class="el-icon-view"></i>
                </div>
              </div>
              <div class="itme_blocks_pages_botton">
                <div class="itme_blocks_pages_botton_summa">
                  {{ number_of_orders_month ? number_of_orders_month : 0 }}
                  {{ $t("message.pieces") }}
                </div>
                <div class="itme_blocks_pages_botton_title">
                  {{ $t("message.number_of_orders_month") }}
                </div>
              </div>
            </div>
          </span>
        </router-link>
      </el-col>
      <!-- end el-col -->
    </el-row>

    <div class="row">
      <div class="col-6 mb-4">
        <div class="row">
          <!-- <div v-can="'home.active_patients'" class="col-6 mb-2">
            <div class="home_link-pages bacg1">
              <i class="flaticon-report-1"></i>
              <div class="text_icon">
                <div>{{ form.active_patients }}</div>
                <span>{{$t('message.active_patients')}}</span>
              </div>
            </div>
          </div> -->

          <div v-can="'home.patients'" class="col-12 mb-2">
            <div class="home_link-pages bacg2">
              <i class="flaticon-report-1"></i>
              <div class="text_icon">
                <div>{{ form.patients }}</div>
                <span>{{ $t("message.patients2") }}</span>
              </div>
            </div>
          </div>

          <div class="col-6 mb-2">
            <div class="home_link-pages bacg3">
              <i class="el-icon-date"></i>
              <div class="text_icon">
                <!-- <div>3</div> -->
                <span>{{ timestamp }}</span>
              </div>
            </div>
          </div>

          <div v-can="'home.orders'" class="col-6 mb-2">
            <div class="home_link-pages bacg4">
              <i class="flaticon-button"></i>
              <div class="text_icon">
                <div>{{ form.orders }}</div>
                <span>{{ $t("message.orders") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-can="'home.transaction_graph'" class="col-6 mb-4">
        <transaction-chart></transaction-chart>
      </div>

      <div v-can="'home.order_graph'" class="col-6 mb-4">
        <order-count-chart></order-count-chart>
      </div>

      <div v-can="'home.cost_transaction_graph'" class="col-6 mb-4">
        <cost-transaction-chart></cost-transaction-chart>
      </div>

      <!-- <div v-can="'home.order_graph'" class="col-6 mb-4">
        <order-chart></order-chart>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import orderChart from "@/views/orders/components/crm-chart";
import orderCountChart from "@/views/orders/components/crm-count-chart";
import transactionChart from "@/views/transactions/components/crm-chart";
import costTransactionChart from "@/views/costTransaction/components/crm-chart";
import patientChart from "@/views/patient/components/crm-chart";
import moment from "moment";
export default {
  name: "Home",
  components: {
    "order-chart": orderChart,
    "transaction-chart": transactionChart,
    "cost-transaction-chart": costTransactionChart,
    "patient-chart": patientChart,
    "order-count-chart": orderCountChart,
  },
  data() {
    return {
      cacheHospital: parseInt(process.env.VUE_APP_CACHE_HOSPITAL),
      hospitalSchedule: parseInt(process.env.VUE_APP_SCHEDULE),
      env_hospital: parseInt(process.env.VUE_APP_HOSPITAL),
      env_medical: parseInt(process.env.VUE_APP_MEDICINE_CABINET),

      redirectDoctor: false,
      form: {},
      loadingData: false,
      timestamp: "",
    };
  },
  computed: {
    ...mapGetters({
      auth_role: "auth/role",
      dashboardInf: "dashboards/dashboardInf",
      schedulePatients: "dashboards/schedulePatients",
      stasionaryPatients: "dashboards/stasionaryPatients",
      paymentsSum: "dashboards/paymentsSum",
      expences: "dashboards/expences",
      contractsCount: "dashboards/contractsCount",
      formTemplates: "dashboards/formTemplates",
      patientScheduleCount: "dashboards/patientScheduleCount",
      stasionaryPayments: "dashboards/stasionaryPayments",
      warehouseCount: "dashboards/warehouseCount",
      number_of_orders_today: "dashboards/number_of_orders_today",
      number_of_orders_month: "dashboards/number_of_orders_month",
    }),
  },
  created() {
    this.dashboardInfo();
    this.fetchData();
    if (this.auth_role.slug === "doctor" && this.redirectDoctor == false) {
      this.$router.push("doctor-cabinet/patients");
      this.redirectDoctor == true;
    }
  },
  mounted() {
    $(".app-main").last().addClass("topClass");
  },
  methods: {
    ...mapActions({
      info: "homes/info",
      dashboardInfo: "dashboards/dashboardInfo",
    }),
    changeCurrentTab(selectedNav) {
      this.$store.commit("CHANGE_CURRENT_TAB", selectedNav);
    },
    fetchData() {
      let date = new Date();
      this.timestamp = moment(date).format("YYYY-MM-DD");

      if (!this.loadingData) {
        this.loadingData = true;
        this.info()
          .then((res) => {
            this.form = res;
            this.loadingData = false;
          })
          .catch((err) => {
            console.log(err);
            this.loadingData = false;
          });
      }
    },
  },
  destroyed() {
    $(".app-main").last().removeClass("topClass");
  },
};
</script>
<style lang="scss">
.mychart {
  border-radius: 10px;
}
.chrttext {
  color: #1e75a8;
  font-size: 19px;
  margin-bottom: 15px;
  font-weight: 700;
}
.charminitext {
  font-size: 13px;
  margin-left: 5px !important;
  color: #a2a7a2;
  font-weight: 500;
}
.home_link-pages i {
  border-radius: 5px;
  height: 70px;
  width: 70px;
  height: 7vw;
  width: 7vw;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.68s ease-out;
  transition: all 0.68s ease-out;
}
.bacg1 i {
  color: #40c9c6;
}
.bacg1:hover i {
  color: #ffffff;
  background-color: #40c9c6;
}
.bacg2 i {
  color: #8e55c7;
}
.bacg2:hover i {
  color: #ffffff;
  background-color: #8e55c7;
}
.bacg3 i {
  color: #f4516c;
}
.bacg3:hover i {
  color: #ffffff;
  background-color: #f4516c;
}
.bacg4 i {
  color: #36a3f7;
}
.bacg4:hover i {
  color: #ffffff;
  background-color: #36a3f7;
}
.bacg1 .text_icon > div {
  font-size: 28px;
  color: #40c9c6;
}
.bacg2 .text_icon > div {
  font-size: 28px;
  color: #8e55c7;
}
.click_link:hover .itme_blocks_pages {
  text-decoration: none;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.click_link .itme_blocks_pages {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}
.itme_blocks_pages {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
  min-height: 160px;
}

.itme_blocks_pages_botton {
  .itme_blocks_pages_botton_summa {
    line-height: 2rem;
    font-weight: 700;
    color: #000;
    font-size: 16px;
  }

  .itme_blocks_pages_botton_title {
    color: #718096;
    font-size: 16px;
  }
}

.itme_blocks_pages_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info_topright {
  width: 40px;
  height: 25px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #91c714;
}
.iconstyle {
  width: 40px;
  height: 40px;
  transition: 0.7s;
  display: inline-block;
}
a:hover {
  text-decoration: none;
}
</style>